import React from "react";
import { Link } from "react-router-dom";
import Lottie from 'react-lottie-player'
import lottieJson from './programming.json'


const Cta = () => {
  return (
    <>
      <section id ="services"
        className="download-section pt-100 background-img"
        style={{
          background:
            "url('assets/img/html.jpg') no-repeat center center / cover",
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="download-content text-white pb-100">
                <h2 className="text-white">
                Web Design & Development                </h2>
                <br/>
                
                <p>
                We design and develop websites and digital experiences that help our clients grow, innovate and transform.Our experienced in-house team has an unmatched depth of expertise across all facets of web design, development, copywriting, SEO, UI/UX and conversion optimization. Waveloop doesn’t just create a website... <br/>We build a sophisticated and successful lead-generating platform.
                </p>

              </div>
            </div>
            <div className="col-md-4">
              <div className="download-img d-flex align-items-end">
                <img
                  src="assets/img/web2.png"
                  alt="download"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cta;
