import React from "react";

export default function FeatureImgTwo() {
  return (
    <>
      <section id="projects" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="about-content-left section-heading">
                <h2 id='projecttitle' >
                  Development of a Digital Platform <br/> for UAV control and Data Acquisition
                </h2>
                <div className="single-feature mb-4 mt-5">
                  <div className="icon-box-wrap d-flex align-items-center mb-2">
                   
                    <p>This project offers a new approach to building inspection (through precision inspection) using an autonomous UAV that flies through narrow, confined spaces to collect useful data, 
                      a task that is difficult and dangerous for humans to conduct. For this project, our team developed a digital platform through which the user can control the UAV and direct it to specific 
                      points to receive data through the UAV’s sensors. The platform enables the user to store each newly performed inspection process and store it in a cloud database for future use. </p>

                  </div>
                  <h5>Key Features</h5>
                  <ul id='keyfeatures'>
                    <li>Through the application the user can control the UAV</li>
                    <li>The user can start a new process and save it, along with the data collected by the UAV's sensors, to the database</li>
                    <li>The user can view and edit an already saved procedure</li>
                    <li>The application can be used by all devices (smartphone, PC, tablet) as it has a responsive design</li>
                    <li>Back-end: Python </li>
                    <li>Front-end: Javascript </li>
                  </ul>
                </div>
            
               
              </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
                <img 
                  src="assets/img/drone.png"
                  alt="about us"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
