import React from "react";
import logo1 from "./code.png"
import logo2 from "./consulting.png"
import logo3 from "./ai.png"
const Promo = () => {
  return (
    <>
      <section className="promo-section ptb-100" id="about">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 col-md-8">
              <div className="section-heading text-center mb-5 " >
                <h2>About us</h2>
                <p className="lead">
                We are a digital transformation consultancy and software development company that provides cutting-edge engineering solutions, enabling our clients to untangle complex issues that always emerge during their digital evolution journey. 
               </p>
               <p className="lead">Established to deliver leading-edge intelligent solutions and provide consulting services to businesses and research organisations, Waveloop was founded with the vision to transform businesses with powerful and adaptable digital solutions that satisfy the needs of today and unlock the opportunities of tomorrow.
               </p>
               <p className="lead">
               Do not wait – Come to us to <span style={{color:'#2aa9ba'}}>catch the wave.</span>
                </p>
              </div>
            </div>
          </div>
          <div className="row equal">
            <div className="col-md-4 col-lg-4">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
             
                  <img src={logo1}></img>
              
                <h5>Web development</h5>
                <p>We provide end-to-end web app development services, tailored exclusively to solve your unique business challenges.</p>
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
              <img src={logo3}></img>
                <h5>Machine Learning & AI</h5>
                <p>
                Our team consists of Engineers and Experts, who have delivered complete projects related to machine learning, computer vision and predictive analytics.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
              <img src={logo2}></img>
                <h5>R&D Consulting</h5>
                <p>
                Have you been in the loop of Grant Funding? Waveloop’s experienced team of engineering consultants and grant funding experts can assist you with different models.                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Promo;
